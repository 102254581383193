let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://t2x8cb1c2e.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://gvvuxmfta0.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://i5cvg2tqs0.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.emeco.forwoodsafety.com',
 forwood_id_url: 'https://id.emeco.forwoodsafety.com?redirect_uri=https://geoeditor.emeco.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.emeco.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_XUVnRJ0bp',
   userPoolWebClientId: '2283353c21gtp3bjpuukrhfj01',
   cookieStorage: {
     domain: '.staging.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
